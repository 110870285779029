<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>客户名称：</span>
          <!--          <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex" @on-change="clienChange" disabled>-->
          <!--            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>-->
          <!--          </Select>-->
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" :value="detailsInfo.customer_name" disabled></i-input>
        </FormItem>
        <FormItem>
          <span style="margin-left: 18px;">经手人：</span>
          <!--          <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.handled_by" clearable filterable disabled>-->
          <!--            <Option v-for="item in handledByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>-->
          <!--          </Select>-->
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" v-model="detailsInfo.handled_by_name" disabled></i-input>
        </FormItem>
        <!--        <FormItem>-->
        <!--          <span>产品名称：</span>-->
        <!--          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" v-model="queryFrom.product_id" clearable filterable :disabled="supplierIndex < 0">-->
        <!--            <Option v-for="item in productList" :value="item.product_id" :key="item.product_id">{{ item.product_name }}</Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
        <!--        <FormItem>-->
        <!--          <span>规格型号/SKU：</span>-->
        <!--          <Select class="iviewIptWidth250" :disabled="!queryFrom.product_id" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="1">-->
        <!--            <Option v-for="(item, index) in modelList" :value="item.code_id" :key="index" :label="item.model_name + '-' + item.item_number">-->
        <!--              <span>{{ item.item_number }}</span>-->
        <!--              <span style="float: right; color: #ccc; margin-right: 20px">{{ item.model_name }}</span>-->
        <!--            </Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
        <!--        <FormItem class="po-create-number marginRight0" :label-width="10">-->
        <!--          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>-->
        <!--        </FormItem>-->
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品" :loading="loading">
        <template slot-scope="{ row, index }" slot="new_sell_quote">
          <div class="danjia"><span class="fl">¥ </span><i-input type="number" class="fl" :value="row.new_sell_quote" placeholder="请输入" style="width: 100px;" @on-change="changeIpt($event, 'new_sell_quote', index)"></i-input></div>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !loading">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div class="other">其它信息</div>
      <div class="fromDiv">
        <div class="formLine borderTopNone">
          <FormLabel label="备注信息"></FormLabel>
          <div class="allWidth fl nowrap">{{ detailsInfo.remark ? detailsInfo.remark : '' }}</div>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormLabel from '@/components/formLabel'
export default {
  name: 'addPurchaseRequisition',
  components: {
    FormLabel,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      loading: true,
      supplierIndex: -1,
      supplierId: '',
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        handled_by: '',
        product_id: '',
        product_code_array: [],
      },
      postFrom: {
        handled_by: '',
        customer_info: {},
        product_info: [],
      },
      handledByList: [], // 经手人
      productList: [],
      modelList: [], // sku列表
      storeList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '原销售价(含税)',
          key: 'old_sell_quote',
          align: 'center',
          width: 170,
        },
        {
          title: '销售报价(含税)',
          key: 'new_sell_quote',
          align: 'center',
          width: 170,
        },
        {
          title: '审核日期',
          key: 'examineTime',
          align: 'center',
          width: 114,
        },
      ],
      clickIndex: Number, // 暂存点击删除数据下标
      detailsInfo: {},
    }
  },
  mounted() {
    this.querySpecialUser()
    this.queryList()
    this.id = this.$route.query.id
    this.queryDetails()
    // this.queryProduct()
  },
  methods: {
    queryDetails() {
      this.loading = true
      this.$http.get(this.$api.saleDadjapOperate, { dadjap_code: this.id }, true).then(res => {
        this.detailsInfo = res.data
        this.meterList = res.data.dadjap_detail
        this.loading = false
        for (let i = 0; i < this.meterList.length; i++) {
          this.meterList[i].examineTime = this.meterList[i].examine_time ? this.$moment(this.meterList[i].examine_time * 1000).format('YYYY-MM-DD') : ''
          this.meterList[i].name = this.meterList[i].product_name
          this.meterList[i].licence_code = this.meterList[i].product_licence_code
          this.meterList[i].item_number = this.meterList[i].product_item_number
          this.meterList[i].unit = this.meterList[i].product_unit
          this.meterList[i].packing_size = this.meterList[i].product_packing_size
          this.meterList[i].model_name = this.meterList[i].product_model_name
        }
      })
    },
    // 客户名称改变
    clienChange(e) {
      if (e >= 0) {
        this.queryProduct('', this.clientNameList[e].customer_id, this.clientNameList[e].customer_type)
      } else {
        this.queryFrom.product_id = ''
        this.queryFrom.product_code_array = []
      }
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 查询产品
    queryProduct(id = null, customer_id, customer_type) {
      this.$http.get(this.$api.productShow, { product_id: id, search_type: '2', customer_id: customer_id, customer_type: customer_type }, false).then(res => {
        if (!id) {
          this.productList = res.data
        } else {
          this.modelList = res.data
        }
      })
    },
    // 查询经手人和审核人
    querySpecialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: 1 }, true).then(res => {
        this.handledByList = res.data
      })
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        let select = this.meterList.map(item => item.product_id + item.code_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.code_id)
        })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
      for (let i = 0; i < this.meterList.length; i++) {
        this.meterList[i].examineTime = this.meterList[i].examine_time ? this.$moment(this.meterList[i].examine_time * 1000).format('YYYY-MM-DD') : ''
      }
      console.log(this.meterList)
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (this.supplierIndex < 0 || !this.queryFrom.product_id) {
        this.$Message.warning('请先选择客户名称/产品名称后再查询')
        return
      }
      let query = {
        product_id: this.queryFrom.product_id,
        product_code_array: this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : '',
        search_type: '2',
        customer_id: this.clientNameList[this.supplierIndex].customer_id,
        customer_type: this.clientNameList[this.supplierIndex].customer_type,
      }
      this.$http.get(this.$api.productSearch, query, false).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          this.chooseStatus = true
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      if (!e) return
      this.modelList = []
      this.queryProduct(e)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
        return null
      } else {
        let str = e.target.value.trim().substring(0, 8)
        this.$set(this.meterList[index], name, str * 1)
        return null
      }
    },
    // 保存
    save() {
      let obj = {}
      if (this.supplierIndex < 0) {
        this.$Message.warning('请选择客户名称')
        return
      }
      if (!this.queryFrom.handled_by) {
        this.$Message.warning('请选择经手人')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        this.meterList[i].product_name = this.meterList[i].name
        this.meterList[i].product_licence_code = this.meterList[i].licence_code
        this.meterList[i].product_item_number = this.meterList[i].item_number
        this.meterList[i].product_unit = this.meterList[i].unit
        this.meterList[i].product_packing_size = this.meterList[i].packing_size
        this.meterList[i].product_model_name = this.meterList[i].model_name
        this.meterList[i].product_code_id = this.meterList[i].code_id
        if (!this.meterList[i].sell_quote) {
          this.$Message.warning(`请完善第${i + 1}组产品的销售报价`)
          return
        }
      }
      this.postFrom.product_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.handled_by = this.queryFrom.handled_by
      this.postFrom.customer_info = this.clientNameList[this.supplierIndex]
      this.postStatus = true
      // this.$http.post(this.$api.saleQuotation, this.postFrom, true).then(res => {
      //   this.$Message.success('提交成功')
      //   this.$router.go(-1)
      // })
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      this.$http.post(this.$api.saleDadjap, this.postFrom, true).then(res => {
        this.$Message.success('提交成功')
        this.$router.go(-1)
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iviewIptWidth270 {
  width: 290px !important;
}
.other {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
  margin: 20px 0;
}
.fromDiv .borderTopNone {
  border-top: 1px solid #e8eaec !important;
}
</style>
